import { Button } from '@/components/ui/button';
import { EventBus } from '@/utils/event-bus';
import { MdLogout } from 'react-icons/md';
import { wbeStore } from '../store';

const Account = () => {
  const {
    userInfo,
    comments,
    schemas,
    permissions,
    dataPermissions,
    commentPermissions,
    columnProperties,
    schemaID,
    removeToken,
  } = wbeStore();

  function getPermissions(items) {
    return items
      .map((p) => {
        return permissions.find((item) => item.id == p)?.display_name;
      })
      .filter((item) => item !== undefined && !item.includes('Bulk') && !item.includes('Rollback'));
  }

  const permissionNames = getPermissions(dataPermissions);
  const commentPermissionNames = getPermissions(commentPermissions);

  // check which fields are editable
  const editableFields = schemas
    .find((s) => s.key === schemaID)
    ?.fields.filter((field) => {
      return columnProperties[field?.label]?.editable;
    })
    .map((item) => item.label);

  function logout() {
    removeToken();
    EventBus.triggerEvent('updateGrid');
  }
  return (
    <div className="flex h-full flex-col justify-between gap-4 p-2 text-lg">
      <div className="flex flex-col gap-8 overflow-y-auto p-3">
        <div>
          <h1 className="text-xl">
            Welcome <span className="w-full text-wrap break-words text-primary">{userInfo?.display_name ?? ''}</span>
          </h1>
          <p>Enjoy writing back data to the database and commenting on cells. </p>
        </div>
        {permissionNames.length > 0 && (
          <div>
            <h2>Tabledata editing</h2>
            {editableFields?.length && editableFields?.length > 0 ? (
              <>
                <p>These columns are editable:</p>
                <ul>
                  {editableFields?.map((item, index) => (
                    <li
                      className="text-lg"
                      style={{ listStyle: 'inside' }}
                      key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
                <br />

                <p>These permissions are enabled for you:</p>
                <ul>
                  {permissionNames.map((p) => (
                    <li
                      className="text-lg"
                      key={p}
                      style={{ listStyle: 'inside' }}>
                      {p}
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <span>No columns are editable</span>
            )}
          </div>
        )}
        <div>
          {comments.enabled && (
            <div>
              <h2>Comment on cells</h2>
              {commentPermissionNames.length > 0 ? (
                <>
                  <p>These permissions are enabled for you:</p>
                  <ul>
                    {commentPermissionNames.map((p) => (
                      <li
                        className="text-lg"
                        key={p}
                        style={{ listStyle: 'inside' }}>
                        {p}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <div>Commenting is enabled but permissions found</div>
              )}
            </div>
          )}
        </div>
        <Button
          variant="link"
          onClick={logout}
          className="justify-normal px-0">
          <div className="items-left flex gap-2">
            <MdLogout size={17} />
            <div className="text-lg">Logout</div>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default Account;
