import { Audit, BulkAudits } from '@/types/writeback';
import autoAnimate from '@formkit/auto-animate';
import { useEffect, useMemo, useRef } from 'react';
import { CellAudit, DefaultAuditFilter } from '.';
import { useWBEContext } from '../context';
import { useWBEStore } from '../store';
import AuditCard from './AuditCard';

type AudistListProps = {
  filter: DefaultAuditFilter | null;
  cellAudit: CellAudit | null;
  setCellAudit: (audit: CellAudit | null) => void;
  search: string;
  sort: 'asc' | 'desc';
};

const AuditsList = ({ filter, cellAudit, setCellAudit, search, sort }: AudistListProps) => {
  const primaryKeySlug = useWBEStore((state) => state.primaryKeySlug);
  const nameMap = useWBEStore((state) => state.nameMap);
  const { audits } = useWBEContext();

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const filteredAudits: Audit[] = useMemo(() => {
    const finalFilter = (filter || cellAudit) && {
      ...filter,
      ...cellAudit,
    };
    return audits.data.filter((audit) => {
      const matchesSearch =
        !search ||
        audit.username.toLowerCase().includes(search.toLowerCase()) ||
        audit.auditable_id.toString().includes(search) ||
        Object.values(audit.changes).join(' ').toLowerCase().includes(search.toLowerCase());

      const columnChanged = Object.keys(audit.changes).find((key) => key !== primaryKeySlug);
      const columnName = columnChanged ? nameMap.get(columnChanged) : '';

      const matchesFilter =
        !finalFilter ||
        ((!finalFilter.column || (columnName && columnName.toLowerCase().includes(finalFilter.column.toLowerCase()))) &&
          (!finalFilter.row || audit.auditable_id.toString().includes(finalFilter.row)) &&
          (!finalFilter.status || audit.status === finalFilter.status) &&
          (!finalFilter.startDate || new Date(audit.created_at) >= finalFilter.startDate) &&
          (!finalFilter.endDate || new Date(audit.created_at) <= finalFilter.endDate) &&
          (!finalFilter.username || audit.username.toLowerCase().includes(finalFilter.username.toLowerCase())));

      return matchesSearch && matchesFilter;
    });
  }, [audits.data, search, filter, cellAudit]);

  const groupedAudits: BulkAudits[] = useMemo(() => groupBulkEdits(filteredAudits), [filteredAudits]);

  const sortedAudits: BulkAudits[] = useMemo(() => {
    return groupedAudits.sort((a, b) => {
      const dateA = new Date(a.updated_at || a.created_at).getTime();
      const dateB = new Date(b.updated_at || b.created_at).getTime();

      return sort === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }, [groupedAudits, sort]);

  return sortedAudits.length === 0 ? (
    <div className="mt-4 text-center">No Audits Found</div>
  ) : (
    <div ref={parent}>
      {sortedAudits.map((audit) => {
        return (
          <AuditCard
            audit={audit}
            key={audit.id}
            setCellAudit={setCellAudit}
          />
        );
      })}
    </div>
  );
};

const groupBulkEdits = (audits: Audit[]): BulkAudits[] => {
  const groupedAudits: BulkAudits[] = [];
  const auditMap = new Map<string, BulkAudits>();

  audits.forEach((audit) => {
    const key = `${audit.user_id}-${audit.updated_at}`;
    if (auditMap.has(key)) {
      auditMap.get(key)!.bulkAudits?.push(audit);
    } else {
      auditMap.set(key, { ...audit, bulkAudits: [] });
      groupedAudits.push(auditMap.get(key)!);
    }
  });

  return groupedAudits;
};

export default AuditsList;
