import { Button } from '@/components/ui/button';
import { Label } from '@/components/ui/label';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { gridApi } from '@/grid';
import { Audit, BulkAudits, WBEColumnMap } from '@/types/writeback';
import { cn } from '@/utils/cn';
import autoAnimate from '@formkit/auto-animate';
import { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiFilter } from 'react-icons/fi'; // Import the FiZap icon
import { GoInfo } from 'react-icons/go';
import { CellAudit } from '.';
import { useWBEStore } from '../store';
import { isString } from 'markdown-it/lib/common/utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';

dayjs.extend(relativeTime);

const AuditCard = ({ audit, setCellAudit }: { audit: BulkAudits; setCellAudit: (CellAudit: CellAudit) => void }) => {
  const primaryKeySlug = useWBEStore((state) => state.primaryKeySlug);
  const primaryKeyColumn = useWBEStore((state) => state.primaryKeyColumn);
  const nameMap = useWBEStore((state) => state.nameMap);
  const columnProperties = useWBEStore((state) => state.columnProperties);
  const columnChanged = Object.keys(audit.changes).find((key) => key !== primaryKeySlug);
  const columnName = nameMap.reverseMap.get(columnChanged!);
  const WBEAudit = useWBEStore((state) => state.audit);
  const { amIAllowedToDo } = useWBEStore();

  const [expanded, setExpanded] = useState(false);

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleToggleReplies = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setExpanded(!expanded);
  };

  const rowID = audit.auditable_id;
  const changeMade = audit.changes[columnChanged!] || 'Removed value';
  const date = dayjs(audit.updated_at || audit.created_at).fromNow();
  const status = !audit.changes[columnChanged!] ? 'Deleted' : 'Updated';

  function viewCellAudits(e) {
    focusCells(e, true);
    setCellAudit({
      column: columnName!,
      row: rowID!,
    });
  }

  function focusCells(e, single = false) {
    e.stopPropagation();
    bringCellIntoFocus(columnChanged, rowID, columnProperties, primaryKeyColumn);
    if (audit.bulkAudits.length > 0 && !single) {
      audit.bulkAudits.forEach((bulkAudit) => {
        const columnChanged = Object.keys(bulkAudit.changes).find((key) => key !== primaryKeySlug);
        const rowID = bulkAudit.auditable_id;
        bringCellIntoFocus(columnChanged, rowID, columnProperties);
      });
    }
  }

  return (
    amIAllowedToDo('read', 'data') && (
      <div
        onClick={(e) => focusCells(e)}
        className={cn(
          'relative mb-4 flex cursor-pointer flex-col gap-1 rounded-lg border border-l-4 bg-white p-4 shadow hover:bg-gray-50'
        )}
        style={{
          borderLeftColor: !audit.changes[columnChanged!] ? WBEAudit.color.deleted : WBEAudit.color.updated,
        }}>
        <div className="flex w-full items-center justify-between gap-x-1 font-karla">
          <h3 className="break-all">@{audit.username}</h3>
          <div className="flex items-center justify-end gap-1">
            <div>
              <TooltipProvider>
                <Tooltip delayDuration={10}>
                  <TooltipTrigger asChild>
                    <div className="font-lg p-1 text-secondary">
                      <GoInfo
                        className="cursor-pointer"
                        size={14}
                      />
                    </div>
                  </TooltipTrigger>
                  <TooltipContent
                    side="top"
                    align="center"
                    className="m-0 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white">
                    <div className="flex items-center gap-x-0.5">
                      <div className="font-semibold">Row{audit.bulkAudits.length > 0 ? 's' : ''}: </div>
                      <div>
                        {audit.auditable_id} {audit.bulkAudits.length > 0 ? `and ${audit.bulkAudits.length} more` : ''}
                      </div>
                    </div>
                    <div className="flex items-center gap-x-0.5">
                      <div className="font-semibold">Column: </div>
                      <div>{columnName}</div>
                    </div>
                    <div className="flex items-center gap-x-0.5">
                      <div className="font-semibold">Status: </div>
                      <div>{status}</div>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
            <TooltipProvider>
              <Tooltip delayDuration={10}>
                <TooltipTrigger asChild>
                  <div
                    onClick={viewCellAudits}
                    className="rounded-md p-1 hover:bg-secondary hover:text-white">
                    <FiFilter size={15} />
                  </div>
                </TooltipTrigger>
                <TooltipContent
                  side="top"
                  align="center"
                  className="m-0 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white">
                  <Label>View all the audits for this cell</Label>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          <div
            className="py-1"
            onClick={(e) => {
              focusCells(e, true);
            }}>
            {isString(changeMade)
              ? changeMade.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              : changeMade.toString()}
          </div>
        </div>
        <div className="text-s text-gray-500">{date}</div>
        <div
          className="flex cursor-pointer flex-col gap-1"
          ref={parent}>
          {audit.bulkAudits.length > 0 && (
            <Button
              size="sm"
              variant="link"
              onClick={handleToggleReplies}>
              <div className="flex items-center gap-x-2 p-0">
                {expanded ? (
                  <>
                    Collapse audits <FiChevronUp size={17} />
                  </>
                ) : (
                  <>
                    Show all audits ({audit.bulkAudits.length}) <FiChevronDown size={17} />
                  </>
                )}
              </div>
            </Button>
          )}

          {expanded && (
            <div className="flex flex-col gap-1">
              {audit.bulkAudits.map((audit) => {
                return (
                  <MiniAuditCard
                    key={audit.id}
                    audit={audit}
                    setCellAudit={setCellAudit}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    )
  );
};

const MiniAuditCard = ({ audit, setCellAudit }: { audit: Audit; setCellAudit: (CellAudit: CellAudit) => void }) => {
  const primaryKeySlug = useWBEStore((state) => state.primaryKeySlug);
  const WBEAudit = useWBEStore((state) => state.audit);
  const columnChanged = Object.keys(audit.changes).find((key) => key !== primaryKeySlug);

  function viewCellAudits() {
    setCellAudit({
      column: columnChanged!,
      row: audit.auditable_id,
    });
  }

  function focusCell(e) {
    e.stopPropagation();
    bringCellIntoFocus(columnChanged, audit.auditable_id, {});
  }

  return (
    <div
      onClick={focusCell}
      className="flex items-center justify-between gap-1 rounded-md border border-l-4 px-2 py-1 hover:bg-gray-100"
      style={{
        borderLeftColor:
          audit.status === 'CREATED'
            ? WBEAudit.color.created
            : audit.status === 'UPDATED'
              ? WBEAudit.color.updated
              : WBEAudit.color.deleted,
      }}>
      <div className="">{audit.auditable_id.toString()}</div>
      <div className="flex items-center">
        <div>
          <TooltipProvider>
            <Tooltip delayDuration={10}>
              <TooltipTrigger asChild>
                <div className="font-lg p-1 text-secondary">
                  <GoInfo
                    className="cursor-pointer"
                    size={14}
                  />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="top"
                align="center"
                className="m-0 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white">
                <div className="flex items-center gap-x-0.5">
                  <div className="font-semibold">Row</div>
                  <div>{audit.auditable_id}</div>
                </div>
                <div className="flex items-center gap-x-0.5">
                  <div className="font-semibold">Status: </div>
                  <div>{audit.status}</div>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div>
          <TooltipProvider>
            <Tooltip delayDuration={10}>
              <TooltipTrigger asChild>
                <div
                  onClick={viewCellAudits}
                  className="rounded-md p-1 hover:bg-secondary hover:text-white">
                  <FiFilter size={15} />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="top"
                align="center"
                className="m-0 mx-8 max-w-4xl break-words bg-secondary font-karla text-lg text-white">
                <Label>View all the audits for this cell</Label>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

function bringCellIntoFocus(
  columnID: string | undefined,
  rowID: string | undefined,
  columnsProperties: WBEColumnMap,
  primaryKeyColumn?: string
) {
  if (!gridApi || !columnID || !rowID) return;
  const columnName = getColumnName(columnID, columnsProperties);
  const getNodeWithValue = (value: string) => {
    let foundNode = null;
    gridApi!.forEachNode((node) => {
      if (primaryKeyColumn && node.data[primaryKeyColumn] == value) {
        foundNode = node;
      }
    });
    return foundNode;
  };
  const column = gridApi!.getColumn(columnName);
  const rowNode = getNodeWithValue(rowID);

  if (!column || !rowNode || typeof rowNode?.rowIndex !== 'number') return;
  gridApi!.ensureColumnVisible(column);
  gridApi!.ensureIndexVisible(rowNode.rowIndex);
  gridApi!.flashCells({
    rowNodes: [rowNode],
    columns: [column.getId()],
    flashDelay: 1000,
  });
}

function getColumnName(name: string, columnsProperties: WBEColumnMap): string {
  const columnProperties = columnsProperties[name];
  return columnProperties?.field || name;
}

export default AuditCard;
