import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useEffect, useState } from 'react';
import { HiOutlineRefresh, HiSortAscending, HiSortDescending } from 'react-icons/hi';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import { useWBEContext } from '../context';
import { useWBEStore } from '../store';
import AuditsList from './AuditsList';
import AuditFilter from './Filter';
import MenuButton from '@/components/ui/menuButton';

export type DefaultAuditFilter = {
  column: string;
  row: string;
  status: 'CREATED' | 'UPDATED' | 'DELETED' | null;
  startDate: Date | null;
  endDate: Date | null;
  username: string;
};

export type CellAudit = {
  column: string;
  row: string;
};

const Audits = () => {
  const { userInfo, audit } = useWBEStore();

  const { audits, rangeSelection } = useWBEContext();

  const [search, setSearch] = useState('');
  const [sort, setSort] = useState<'asc' | 'desc'>('desc');
  const [filter, setFilter] = useState<DefaultAuditFilter | null>(null);
  const [cellAudit, setCellAudit] = useState<CellAudit | null>(null);
  const [usernames, setUsernames] = useState<string[]>([]);

  useEffect(() => {
    if (audits.data) {
      const users = audits.data.map((audit) => audit.username);
      setUsernames(Array.from(new Set(users)));
    }
  }, [audits.data]);

  useEffect(() => {
    if (rangeSelection.length === 0) return setCellAudit(null);
    if (rangeSelection.length !== 1) return setCellAudit(null);
    const cell = rangeSelection[0];
    setCellAudit({
      column: cell.column,
      row: cell.row,
    });
  }, [rangeSelection]);

  if (audits.isLoading) return <div>Loading...</div>;
  if (audits.isError) return <div>Error fetching audits</div>;

  if (!audit.enabled) {
    return (
      <>
        <div className="m-4 mt-4">
          <h1 className="text-xl">View changes made in the table</h1>
          <br />
          Discover who made changes to which data and when they occurred.
        </div>
        <div className="m-4 mt-4 text-lg font-bold text-red-500">Viewing audit data is currently not enabled.</div>
      </>
    );
  }

  return (
    <div className="flex h-full flex-col overflow-hidden py-2">
      <div className="border-b border-gray-300 px-2 pb-3">
        <div className="flex items-center justify-between gap-2">
          <div className="mt-[2px] flex-1">
            <Input
              className="flex-1 py-0"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="mt-[-5px] flex items-center gap-2">
            <AuditFilter
              filter={filter}
              setFilter={setFilter}
              currentUser={userInfo?.username || ''}
              usernames={usernames}
            />
            <MenuButton
              icon={sort === 'desc' ? <HiSortDescending size={20} /> : <HiSortAscending size={20} />}
              onClick={() => setSort(sort === 'asc' ? 'desc' : 'asc')}
              title="Sort the audits by the date they were created"
            />
            <MenuButton
              icon={<HiOutlineRefresh size={20} />}
              onClick={() => audits.refetch()}
              shouldRotate={true}
              title="Refresh audits"
            />
          </div>
        </div>
        {cellAudit && (
          <div className="flex flex-col gap-1 py-1">
            <Button
              variant="link"
              onClick={() => setCellAudit(null)}
              className="w-fit p-0">
              <MdOutlineArrowBackIos
                className=""
                size={15}
              />
              <div>Back to all audits</div>
            </Button>
          </div>
        )}
      </div>
      <div className="flex-1 overflow-auto px-2 pt-4">
        <AuditsList
          filter={filter}
          cellAudit={cellAudit}
          setCellAudit={setCellAudit}
          search={search}
          sort={sort}
        />
      </div>
    </div>
  );
};

export default Audits;
