import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { gridApi } from '@/grid';
import { Comment, CommentWithReplies, PostComment } from '@/types/writeback';
import { cn } from '@/utils/cn';
import autoAnimate from '@formkit/auto-animate';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FaReply, FaSave } from 'react-icons/fa';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { GoInfo } from 'react-icons/go';
import { HiDotsVertical } from 'react-icons/hi';
import { CellComment } from '.';
import { deleteComment as apiDeleteComment, setBulkRows } from '../api';
import { useWBEContext } from '../context';
import { useWBEStore } from '../store';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { handleError } from '@/utils/error';

dayjs.extend(relativeTime);

const CommentCard = ({
  comment,
  refreshComments,
  setCellComment,
}: {
  comment: CommentWithReplies;
  refreshComments: () => void;
  setCellComment: (cellComment: CellComment) => void;
}) => {
  const { url, comments, token, amIAllowedToDo, isCreatedByMe } = useWBEStore();

  const [expanded, setExpanded] = useState(false);
  const [showReply, setShowReply] = useState(false);

  const maxVisibleReplies = 1;
  const orderedReplies = useMemo(
    () => comment.children?.sort((a, b) => a.created_at.localeCompare(b.created_at)),
    [comment.children]
  );
  const primaryKeySlug = useWBEStore((state) => state.primaryKeyColumn);
  const columnConfig = useWBEStore((state) => state.columnConfig);

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const handleToggleReplies = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  async function changeResolved() {
    handleError(async () => {
      const bulkData: {
        [key: string]: any;
      } = [{ id: comment.id, resolved: !comment.resolved }];

      await setBulkRows(url, token, comments.schemaID, bulkData);
      refreshComments();
    });
  }

  async function updateComment(id: string, comment: string) {
    handleError(async () => {
      const bulkData: {
        [key: string]: any;
      } = [{ id: id, comment: comment }];

      await setBulkRows(url, token, comments.schemaID, bulkData);
      refreshComments();
    });
  }

  function viewAllComments(comment: Comment) {
    const primaryKey = columnConfig?.find((column) => {
      return column.name === primaryKeySlug;
    });

    bringCellIntoFocus(comment, primaryKey);
    setCellComment({
      column: comment.column_id,
      row: comment.row_id,
    });
  }

  async function deleteComment(comment: Comment) {
    handleError(async () => {
      await apiDeleteComment(url, token, comments.schemaID, comment.id);
      refreshComments();
    });
  }

  return (
    amIAllowedToDo(isCreatedByMe(comment.user_id) ? 'read' : 'read-others', 'comment') && (
      <div
        onClick={() => bringCellIntoFocus(comment, primaryKeySlug)}
        className={cn('mb-4 cursor-pointer flex-col rounded-lg border border-l-4 bg-white p-4 shadow')}
        style={{
          borderLeftColor: comment.resolved ? comments.color.resolved : comments.color.unresolved,
        }}>
        <CommentRecord
          comment={comment}
          changeResolved={changeResolved}
          viewAllComments={viewAllComments}
          deleteComment={deleteComment}
          showReply={setShowReply}
          updateComment={updateComment}
          amIAllowedToDo={amIAllowedToDo}
          isCreatedByMe={isCreatedByMe}
          showTooltip
        />
        {orderedReplies?.slice(0, expanded ? undefined : maxVisibleReplies)?.map((reply) => (
          <CommentRecord
            key={reply.id}
            comment={reply}
            changeResolved={changeResolved}
            viewAllComments={viewAllComments}
            deleteComment={deleteComment}
            updateComment={updateComment}
            amIAllowedToDo={amIAllowedToDo}
            isCreatedByMe={isCreatedByMe}
            isReply={true}
            showReply={setShowReply}
          />
        ))}
        {orderedReplies && orderedReplies.length > maxVisibleReplies && (
          <ToggleReplies
            expanded={expanded}
            onToggle={handleToggleReplies}
            orderedReplies={orderedReplies}
            maxVisibleReplies={maxVisibleReplies}
          />
        )}
        {amIAllowedToDo('write', 'comment') && showReply && (
          <AddReplyToComment
            comment={comment}
            refreshComments={refreshComments}
            showReply={setShowReply}
          />
        )}
      </div>
    )
  );
};

const TooltipIcon = ({ comment }) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={10}>
        <TooltipTrigger asChild>
          <div className="font-lg text-secondary">
            <GoInfo
              className="cursor-pointer"
              size={14}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent
          side="top"
          align="center"
          className="m-0 mx-8 max-w-4xl break-words border-0 bg-secondary font-karla text-lg font-normal text-white">
          <div className="flex items-center gap-x-0.5">
            <div className="font-semibold">Row: </div>
            <div>{comment.row_id}</div>
          </div>
          <div className="flex items-center gap-x-0.5">
            <div className="font-semibold">Column: </div>
            <div>{comment.column_id}</div>
          </div>
          <div className="flex items-center gap-x-0.5">
            <div className="font-semibold">Resolved: </div>
            <div>{comment.resolved ? 'Yes' : 'No'}</div>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
const ToggleReplies = ({ expanded, orderedReplies, maxVisibleReplies, onToggle }) => {
  return (
    <Button
      variant="link"
      onClick={onToggle}
      className="p-0">
      <div className="flex items-center gap-x-2 p-0">
        {expanded ? (
          <>
            Collapse replies <FiChevronUp size={17} />
          </>
        ) : (
          <>
            Show all replies ({orderedReplies.length - maxVisibleReplies}) <FiChevronDown size={17} />
          </>
        )}
      </div>
    </Button>
  );
};

const Options = ({
  comment,
  changeResolved,
  viewAllComments,
  deleteComment,
  showReply,
  editComment,
  amIAllowedToDo,
  isCreatedByMe,
  isReply,
}) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      style={{ marginTop: '2px' }}>
      <DropdownMenu>
        <DropdownMenuTrigger style={{ backgroundColor: 'white' }}>
          <HiDotsVertical size={15} />
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {amIAllowedToDo(isCreatedByMe(comment.user_id) ? 'write' : 'write-others', 'comment') && (
            <>
              {<DropdownMenuItem onClick={() => showReply(true)}>New reply</DropdownMenuItem>}
              <DropdownMenuItem onClick={() => editComment(comment)}>
                Edit {isReply ? 'reply' : 'comment'}
              </DropdownMenuItem>
            </>
          )}
          {amIAllowedToDo(isCreatedByMe(comment.user_id) ? 'delete' : 'delete-others', 'comment') && (
            <DropdownMenuItem onClick={() => deleteComment(comment)}>
              Delete {isReply ? 'reply' : 'comment'}
            </DropdownMenuItem>
          )}

          {!isReply && (
            <>
              {amIAllowedToDo(isCreatedByMe(comment.user_id) ? 'write' : 'write-others', 'comment') && (
                <>
                  {!isReply && (
                    <DropdownMenuItem onClick={changeResolved}>
                      Mark as
                      {comment.resolved ? ' unresolved' : ' resolved'}
                    </DropdownMenuItem>
                  )}
                </>
              )}
              <DropdownMenuItem onClick={() => viewAllComments(comment)}>
                View all comments of this cell
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
const CommentRecord = ({
  comment,
  changeResolved,
  viewAllComments,
  deleteComment,
  updateComment,
  showReply,
  amIAllowedToDo,
  isCreatedByMe,
  showTooltip = false,
  isReply = false,
}: {
  comment: Comment;
  changeResolved: () => void;
  viewAllComments: (comment: Comment) => void;
  deleteComment: (comment: Comment) => void;
  showReply: (shouldShow: boolean) => void;
  updateComment: (id: string, comment: string) => void;
  amIAllowedToDo: (permission: string, type: string) => boolean;
  isCreatedByMe: (user: number) => boolean;
  showTooltip?: boolean;
  isReply?: boolean;
}) => {
  const dateCreated = dayjs(comment.created_at).fromNow();
  const dateUpdated = dayjs(comment.updated_at).fromNow();
  const wasUpdated = comment.created_at !== comment.updated_at;

  const [isEditing, setIsEditing] = useState(false);
  function toggleEditComment() {
    setIsEditing(!isEditing);
  }
  function commentUpdated(id: string, comment: string) {
    toggleEditComment();
    updateComment(id, comment);
  }

  return (
    amIAllowedToDo(isCreatedByMe(comment.user_id) ? 'read' : 'read-others', 'comment') && (
      <div className="pb-2 font-karla">
        <div className="mb-3 flex items-center justify-between gap-x-1">
          <h3 className="break-all">@{comment.created_by}</h3>
          <div className="flex items-center justify-end gap-1">
            {showTooltip && <TooltipIcon comment={comment} />}
            <Options
              comment={comment}
              changeResolved={changeResolved}
              viewAllComments={viewAllComments}
              deleteComment={deleteComment}
              showReply={showReply}
              editComment={toggleEditComment}
              amIAllowedToDo={amIAllowedToDo}
              isCreatedByMe={isCreatedByMe}
              isReply={isReply}
            />
          </div>
        </div>
        {isEditing ? (
          <EditComment
            comment={comment}
            updateComment={commentUpdated}
          />
        ) : (
          <div>{comment.comment}</div>
        )}
        <div className="text-s mt-2 text-gray-500">
          {dateCreated}
          {wasUpdated ? ' (updated ' + dateUpdated + ')' : ''}
        </div>
      </div>
    )
  );
};
const AddReplyToComment = ({ comment, refreshComments, showReply }) => {
  const [reply, setReply] = useState('');
  const {
    comments: { addComment },
  } = useWBEContext();

  const userInfo = useWBEStore((state) => state.userInfo);

  async function addReply() {
    if (!reply) return;

    const replyObject: PostComment = {
      row_id: comment.row_id,
      column_id: comment.column_id,
      comment: reply,
      parent_id: comment.id,
      resolved: false,
      created_by: userInfo?.display_name ?? 'Anonymous',
    };

    await addComment.mutateAsync(replyObject);
    showReply(false);
    setReply('');
    refreshComments();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      addReply();
    }
  }

  return (
    <div className="flex items-center gap-1">
      <Input
        className="flex-1 py-0"
        id="new-comment"
        placeholder="Add a reply"
        value={reply}
        onChange={(e) => setReply(e.currentTarget.value)}
        onKeyDown={handleKeyDown}
        onClick={(e) => e.stopPropagation()}
      />
      <div
        onClick={addReply}
        className={cn('p-1', {
          'cursor-pointer hover:bg-gray-200': reply.length > 0,
          'cursor-not-allowed text-gray-400': reply.length === 0,
        })}>
        <FaReply
          size={15}
          className="text-black"
        />
      </div>
    </div>
  );
};

const EditComment = ({
  comment,
  updateComment,
}: {
  comment: Comment;
  updateComment: (id: string, comment: string) => void;
}) => {
  const [localComment, setLocalComment] = useState(comment.comment);

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateComment(comment.id, localComment);
    }
  }

  return (
    <div className="flex items-center gap-2">
      <Input
        className="flex-1 py-0"
        id="edit-comment"
        value={localComment}
        onKeyDown={handleKeyDown}
        onChange={(e) => setLocalComment(e.currentTarget.value)}
        onClick={(e) => e.stopPropagation()}
      />
      <div
        onClick={() => updateComment(comment.id, localComment)}
        className={cn('p-1', {
          'cursor-pointer hover:bg-gray-200': comment.comment.length > 0,
          'cursor-not-allowed text-gray-400': comment.comment.length === 0,
        })}>
        <FaSave
          size={15}
          className="text-black"
        />
      </div>
    </div>
  );
};

function bringCellIntoFocus(comment: Comment, primaryKey: any) {
  const { column_id, row_id } = comment;

  const getNodeWithValue = (value: string) => {
    let foundNode = null;
    gridApi!.forEachNode((node) => {
      if (node.data[primaryKey] == value) {
        foundNode = node;
      }
    });
    return foundNode;
  };

  const column = gridApi!.getColumn(column_id);
  const rowNode = getNodeWithValue(row_id);
  if (!column || !rowNode || typeof rowNode?.rowIndex !== 'number') {
    return;
  }

  gridApi!.ensureColumnVisible(column);
  gridApi!.ensureIndexVisible(rowNode.rowIndex);

  gridApi!.flashCells({
    rowNodes: [rowNode],
    columns: [column.getId()],
    flashDelay: 1000,
  });
}

export default CommentCard;
